<template>
  <form @submit.prevent="submit">
    <ValidationObserver ref="observer">
      <div :class="{ 'modal-card': inModal }" style="width: auto">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">
            {{ edit === false ? "Create a new" : "Edit" }} {{ type }}
          </p>
        </header>
        <section :class="{ 'modal-card-body': inModal }">
          <div class="columns is-multiline">
            <div v-if="edit" class="column is-12">
              <b-field label="Profile Picture">
                <b-upload
                    :disabled="!canEdit"
                    :loading="loadingPicture"
                    @input="readyForCrop"
                    drag-drop
                >
                  <b-image v-if="userObject.avatar!=='https://via.placeholder.com/300x300.png?text=Placeholder'"
                           :responsive="true"
                           :src="userObject.avatar"
                           :rounded="false"
                           class="px-2 py-2 has-radius"
                           alt="User avatar"
                  ></b-image>

                  <p class="is-size-7 px-2 py-2" v-else>
                    Please note, the image should be sufficiently suitable and appropriate to be displayed on the cover
                    page of a student's academic report. The image should be at least 200kB and no more than 3MB in
                    size.
                  </p>
                </b-upload>
              </b-field>
            </div>
            <div class="column is-12">
              <b-field v-if="edit && user.deleted_at!==null" group-multiline grouped>
                <b-field type="is-danger" expanded>
                  <b-input disabled
                           :value="`This user was deleted at ${ formatDate(user.deleted_at) }`"></b-input>

                </b-field>
              </b-field>
              <b-field group-multiline grouped>
                <b-field expanded label="First Name">
                  <b-field>
                    <b-input
                        autocomplete="off"
                        :icon="$tc('icons.user')"
                        v-model="user.first_name"
                        placeholder="First Name"
                        name="first_name"
                        required
                    />
                  </b-field>
                </b-field>
                <b-field expanded label="Last Name">
                  <b-field>
                    <b-input
                        autocomplete="off"
                        :icon="$tc('icons.user')"
                        v-model="user.last_name"
                        placeholder="Last Name"
                        name="last_name"
                        required
                    />
                  </b-field>
                </b-field>
              </b-field>

              <b-field label="Email Address">
                <b-field>
                  <b-input
                      autocomplete="off"
                      icon="email"
                      type="email"
                      v-model="user.email"
                      placeholder="E-mail"
                      name="email"
                      required
                  />
                </b-field>
              </b-field>

              <b-field grouped class="mb-5" group-multiline>
                <b-field expanded label="Home Number">
                  <vue-tel-input v-if="user.primary_contact_number.length>0" :inputOptions="{required:false}" ref="primary_contact_number" :defaultCount="+27" :autoFormat="false" :enabledCountryCode="true"
                                 :mode="'international'"
                                 :invalidMsg="'Please enter a valid phone number'"
                                 v-model="user.primary_contact_number"></vue-tel-input>
                                    <b-input v-else
                                             @input="keepTyping($event,'primary_contact_number')"
                                        autocomplete="off"
                                        v-model="user.primary_contact_number"
                                        name="phone"
                                        type="tel"
                                        validation-message="Please enter a valid phone number"
                                        pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                                        :icon="$tc('icons.phone')"
                                        placeholder="072 555 5555"
                                        expanded
                                    />
                </b-field>
                <b-field expanded label="Work Number">
                  <vue-tel-input v-if="user.secondary_contact_number.length>0" :inputOptions="{required:false}" :autoFormat="false" ref="secondary_contact_number" :defaultCount="+27" :enabledCountryCode="false"
                                 :mode="'international'"
                                 :invalidMsg="'Please enter a valid phone number'" :validCharactersOnly="true"
                                 v-model="user.secondary_contact_number"></vue-tel-input>
                                    <b-input
                                        @input="keepTyping($event,'secondary_contact_number')"

                                        v-else
                                        autocomplete="off"
                                        v-model="user.secondary_contact_number"
                                        name="phone"
                                        :icon="$tc('icons.phone')"
                                        type="tel"
                                        validation-message="Please enter a valid phone number"
                                        pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                                        placeholder="072 555 5555"
                                        expanded
                                    />
                </b-field>
                <b-field expanded :label="$tc('Cellphone', 1) + ' Number'">
                  <vue-tel-input v-if="user.cellphone_number.length>0" :inputOptions="{required:false}" :autoFormat="false" ref="cellphone_number" :defaultCount="+27" :enabledCountryCode="false"
                                 :mode="'international'"
                                 :invalidMsg="'Please enter a valid phone number'" :validCharactersOnly="true"
                                 v-model="user.cellphone_number"></vue-tel-input>
                                    <b-input v-else
                                             @input="keepTyping($event,'cellphone_number')"

                                             autocomplete="off"
                                        v-model="user.cellphone_number"
                                        name="phone"
                                        :icon="$tc('icons.phone')"
                                        type="tel"
                                        validation-message="Please enter a valid phone number"
                                        pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                                        placeholder="072 555 5555"
                                        expanded
                                    />
                </b-field>
              </b-field>
              <b-field grouped group-multiline>
                <b-field expanded label="Title">
                  <b-select
                      expanded
                      v-model="user.title"
                      placeholder="Select a title"
                  >
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                    <option value="Dr">Dr</option>
                  </b-select>
                </b-field>
                <b-field v-if="edit" label="Accepted Terms on">
                  <b-input disabled="" :value="formatDate(user.accepted_terms_at)"></b-input>
                </b-field>
                <b-field
                    v-if="
                      (showRoles && edit &&
                        !!$store.getters['entities/user-permissions/find'](
                          'assign any role'
                        )) ||
                      (!edit && type === 'Staff Member')
                    "
                    expanded
                    label="Roles"
                >
                  <ValidationProvider
                      rules="required:min-items:1"
                      v-slot="{ errors }"
                  >
                    <b-dropdown
                        expanded
                        v-model="user.roles"
                        position="is-top-right"
                        scrollable
                        max-height="400"
                        multiple
                        aria-role="list"
                    >
                      <b-button expanded
                                class="select" :class="{'is-empty':user.roles.length===0}"
                                slot="trigger"
                      >
                        <span>Select Roles: [{{ user.roles.length }}]</span>

                      </b-button>
                      <b-dropdown-item @click.native.prevent="user.roles = []" :value="null">
                        <span>none</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                          v-for="(role, index) in roles"
                          :key="index"
                          v-show="hasPermissionToAssign(role.name)"
                          :value="role.name"
                          aria-role="listitem"
                      >
                        <span>{{ role.name }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
                <b-field v-if="showCampuses &&  campuses.length>0 && defaultRole ==='learner'" label="Campus">
                  <b-select @input="selectCampus" v-model="user.campuses[0]">
                    <option v-for="campus in campuses" :key="campus.id" :value="campus.id">{{ campus.name }}</option>
                  </b-select>
                </b-field>
                <b-field expanded v-if="showCampuses &&  campuses.length > 0 && defaultRole !=='learner'"
                         label="Campuses">
                  <ValidationProvider
                      rules="required:min-items:1"
                      v-slot="{ errors }"
                  >
                    <b-dropdown
                        name="campuses"
                        expanded
                        v-model="user.campuses"
                        position="is-top-right"
                        scrollable
                        max-height="400"
                        multiple
                        aria-campus="list"
                    >
                      <b-button
                          expanded
                          class="select" :class="{'is-empty':user.roles.length===0}"
                          slot="trigger"
                      >
                          <span
                          >Select Campuses: [{{ user.campuses.length }}]</span
                          >

                      </b-button>

                      <b-dropdown-item
                          v-for="(campus, index) in campuses"
                          :key="index"
                          :value="campus.id"
                          aria-campus="listitem"
                      >
                        <span>{{ campus.name }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
                <b-field v-if="!!$store.getters['entities/user-permissions/find']('change users passwords')&&edit"
                         :label="`Change User Password`">
                  <b-button
                      @click="openPasswordModal" type="is-primary">
                    Create New Password
                  </b-button>
                </b-field>
              </b-field>
            </div>


          </div>

          <div v-if="!edit">
            <b-field label="Password">
              <ValidationProvider
                  rules="required|min|password:@confirm"
                  v-slot="{ errors }"
              >
                <b-input
                    type="password"
                    :required="edit"
                    placeholder="password"
                    v-model="user.password"
                    name="password"
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>

            <b-field label="Confirm password">
              <ValidationProvider
                  rules="required"
                  name="confirm"
                  v-slot="{ errors }"
              >
                <b-input
                    :required="edit"
                    placeholder="confirm password"
                    type="password"
                    v-model="confirm_password"
                />
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </div>
        </section>
        <hr v-if="!inModal"/>
        <footer v-if="inModal" class="modal-card-foot">
          <button
              :loading="loading"
              class="button"
              type="is-primary"
              :icon-right="$tc(`icons.${edit === false ? 'create' : 'edit'}`)"
          >
            Save
          </button>
        </footer>
        <b-field grouped position="is-centered" class="mt-5" v-else>
          <div class="control">
            <b-button
                :loading="loading"
                native-type="submit"
                tag="input"
                type="is-primary"
                value="Save"
            >
            </b-button>
          </div>
          <div v-if="edit && canDelete" class="control">
            <b-button
                :loading="loadingDelete"
                @click.prevent="startDelete"
                type="is-danger"
            >
              Delete
            </b-button>
          </div>
        </b-field>
      </div>
    </ValidationObserver>
  </form>
</template>
<script>
import Role from "@/models/Role";
import User from "@/models/User";
import RoleUser from "@/models/RoleUser";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import checkExtension from "@/mixins/FileTypes";
import UserPasswordForm from "@/components/users/UserPasswordForm";
import {format} from 'date-fns';
import ImageCropModal from "@/components/media/ImageCropModal";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("password", {
  params: ["target"],
  validate(value, {target}) {
    return value === target;
  },
  message: "Password confirmation does not match",
});
extend("min", (value) => {
  if (value.length >= 8) {
    return true;
  }
  return "The password must be at least 8 characters";
});
extend("min-items", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
});
export default {
  name: "UserCreateForm",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [checkExtension],

  data() {
    return {
      loadingPicture: false,
      profilePicture: null,
      loading: false,
      confirm_password: null,
      loadingDelete: false,
      user: {
        campuses: [this.$store.state.campus.selected_campus_id],
        title: null,
        first_name: null,
        last_name: null,
        email: null,
        primary_contact_number: "",
        secondary_contact_number: "",
        cellphone_number: "",
        password: null,
        roles: [this.defaultRole],
      },
      // ordinality: 0,
      // physical_address: {
      //   line_1: '',
      //   line_2: '',
      //   city: '',
      //   province: '',
      //   postal_code: '',
      //   country: ''
      // }
    };
  },
  computed: {
    roles() {
      return Role.all();
    },
  },
  methods: {
    readyForCrop(e) {
      console.log(e)
      if (e !== null) {
        if (!this.checkExtension(e, ['jpg', 'jpeg', 'png'])) {
          this.profilePicture = null
        } else {
          this.launchCropModal(e)
          // this.loadingPicture = true;
          // let formData = new FormData();
          // formData.append("image", this.profilePicture);
          // User.Attach(this.user.id, formData)
          //     .then(() => {
          //       this.$buefy.snackbar.open(`Profile picture uploaded!`);
          //       this.loadingPicture = false;
          //     })
          //     .catch((err) => {
          //       this.loadingPicture = false;
          //       if (err.response.status === 422) {
          //         this.handleError(err)
          //       } else {
          //         this.handleError(err)
          //       }
          //     });
        }
      }


    },
    launchCropModal(image) {
      this.$buefy.modal.open({
        parent: this,
        component: ImageCropModal,
        props: {
          image: image,
          inModal: true
        },
        hasModalCard: false,
        trapFocus: true,
        events: {
          "cropped": (image) => {
            this.profilePicture = image
          },
        }
      })
    },
    formatDate(date) {
      if (date !== null && typeof date !== 'undefined') {
        date = new Date(date)
        return format(date, 'yyyy-MM-dd')
      }
      return null
    },
    keepTyping(event, ref) {
      this.$nextTick(() => {
        this.$refs[ref].focus()
      })
    },
    openPasswordModal() {
      this.$buefy.modal.open({
        parent: this,
        component: UserPasswordForm,
        props: {
          user_id: parseInt(this.userObject.id)
        },
        hasModalCard: true,
        trapFocus: true
      })
    },
    selectCampus(option) {
      this.$store.dispatch('campus/selectCampus', option)
    },
    hasPermissionToAssign(role) {
      if (
          this.$store.getters["entities/user-permissions/find"]("assign any role")
      ) {
        return true;
      }
      return !!this.$store.getters["entities/user-permissions/find"](
          "assign " + role + " role"
      );
    },
    startDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting ${this.type}`,
        confirmText: `Delete ${this.type}`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this ${this.type}?`,
        onConfirm: () => this.delete(),
      });
    },
    delete() {
      User.Delete(this.user.id);
      this.$buefy.snackbar.open(`${this.type} deleted!`);
      this.$emit("close");
      this.$emit("deleted");
    },
    submit() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          this.$store.dispatch('toast/createToast', {message: 'Check the password and roles'})
          return;
        }
        if (this.edit) {
          if (this.canEdit) {
            this.loading = true;
            User.Update(
                {
                  ...{
                    campuses: this.user.campuses,
                    title: this.user.title,
                    first_name: this.user.first_name,
                    last_name: this.user.last_name,
                    email: this.user.email,
                    id: this.user.id,
                    primary_contact_number: this.user.primary_contact_number.length>0?this.$refs.primary_contact_number.phoneObject.number:"",
                    secondary_contact_number: this.user.secondary_contact_number.length>0?this.$refs.secondary_contact_number.phoneObject.number:"",
                    cellphone_number: this.user.cellphone_number.length>0?this.$refs.cellphone_number.phoneObject.number:"",
                  },
                  ...(this.$store.getters["entities/user-permissions/find"](
                      "assign any role"
                  )
                      ? {roles: this.user.roles}
                      : {}),
                },
                true
            )
                .then(() => {
                  RoleUser.deleteAll()

                  User.FetchById(this.user.id, ["roles", "campuses"]).then(() => {
                    this.$buefy.snackbar.open(`${this.type} updated!`);
                    this.$emit("close");
                    this.loading = false;
                  });
                })
                .catch((err) => {
                  if (err.response.status === 422) {
                    this.handleError(err)
                  } else {
                    this.handleError(err)
                  }
                  this.loading = false;
                });
          } else {
            this.$store.dispatch("toast/createToast");
          }
        } else {
          if (this.canCreate) {
            this.$refs.observer.validate().then((valid) => {
              if (!valid) {
                return;
              }
              this.loading = true;
              let storeUser = JSON.parse(JSON.stringify(this.user))

              storeUser.primary_contact_number = storeUser.primary_contact_number.length>0?this.$refs.primary_contact_number.phoneObject.number:null
              storeUser.secondary_contact_number = storeUser.secondary_contact_number.length>0?this.$refs.secondary_contact_number.phoneObject.number:null
              storeUser.cellphone_number = storeUser.cellphone_number.length>0?this.$refs.cellphone_number.phoneObject.number:null
              User.Store(storeUser)
                  .then((response) => {
                    this.$buefy.snackbar.open(`${this.type} created!`);
                    this.$emit("user-created", response.entities.users[0]);
                    this.$emit("close");
                    this.loading = false;
                  })

                  .catch((err) => {
                    if (err.response.status === 422) {
                      this.handleError(err)
                    } else {
                      this.handleError(err)
                    }
                    this.loading = false;
                  });
            });
          } else {
            this.$store.dispatch("toast/createToast");
          }
        }
      });
    },
  },
  watch: {

    profilePicture(newValue) {
      if (newValue !== null) {


        this.loadingPicture = true;
        let formData = new FormData();
        formData.append("image", this.profilePicture, 'Avatar');
        User.Attach(this.user.id, formData)
            .then(() => {

              this.$buefy.snackbar.open(`Profile picture uploaded!`);
              User.FetchById(this.user.id).then(() => {
                this.$store.state.user.avatar = this.user.avatar
              })
              this.loadingPicture = false;
            })
            .catch((err) => {
              this.loadingPicture = false;
              if (err.response.status === 422) {
                this.handleError(err)
              } else {
                this.handleError(err)
              }
            });

      }
    },
    userObject() {
      this.user = JSON.parse(JSON.stringify(this.userObject));
      this.user.roles = JSON.parse(
          JSON.stringify(
              this.userObject.roles.map((role) => {
                return role.name;
              })
          )
      );
      this.user.campuses = JSON.parse(
          JSON.stringify(this.userObject.campus_ids)
      );
      delete this.user.password;
    },
  },
  mounted() {
    if (this.edit) {
      this.user = JSON.parse(JSON.stringify(this.userObject));
      this.user.roles = JSON.parse(
          JSON.stringify(
              this.userObject.roles.map((role) => {
                return role.name;
              })
          )
      );
      this.user.campuses = JSON.parse(
          JSON.stringify(this.userObject.campus_ids)
      );
      delete this.user.password;
    }
  },
  props: {
    showRoles: {
      type: Boolean,
      default() {
        return true;
      }
    }, showCampuses: {
      type: Boolean,
      default() {
        return true;
      }
    },
    canCreate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEdit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    campuses: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default() {
        return "Staff Member";
      },
    },
    defaultRole: {
      type: String,
      default() {
        return null;
      },
    },
    userObject: {
      type: Object,
      default() {
        return {
          name: "",
          ordinality: 0,
        };
      },
    },
    inModal: {
      type: Boolean,
      default() {
        return false;
      },
    },
    edit: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>


