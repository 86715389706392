import {DialogProgrammatic as Dialog} from 'buefy'

export default {
  methods:
      {
        checkExtension(file, valid = ['pdf', 'jpeg', 'png', 'gif', 'pdf', 'doc', 'docx', 'txt', 'text', 'xls', 'xlsx']) {

          let extension = file.type.substring(file.type.lastIndexOf('/') + 1)

          if (valid.includes(extension)) {
            return true
          }
          let validList = ''
          for (let type of valid) {
            validList += `<li>${type}</li>`
          }
          Dialog.alert({
            title: "Non accepted file type",
            message: `You are trying to upload a file of a non-accepted type. Only the following file types are allowed: <div class="content"><ul>${validList}</ul></div>`,
            type: "is-danger",
            hasIcon: true,
            confirmText: 'Ok',
            onConfirm: () => {
              return false
            },

          });
        }
      }
}
