<template>
  <div :class="{'modal-card':inModal}" style="width: auto">
    <header v-if="inModal" class="modal-card-head">
      <p class="modal-card-title">Edit Image</p>
    </header>
    <section :class="{'modal-card-body':inModal}">
      <!-- Note that 'ref' is important here (value can be anything). read the description about `ref` below. -->
      <div class="cropper-container">
        <vue-croppie
            ref="croppieRef"
            :enableOrientation="true"
            :enableResize="false"
            :viewport="{ width: 220, height: 220 }"
            @result="result"
        >
        </vue-croppie>
      </div>

      <!-- the result -->
      <!--      <img v-bind:src="cropped">-->

      <b-field class="mt-6" expanded position="is-centered" style="width: 100%;">
<span class="control">        <b-button type="is-secondary" @click="rotate(-90)">Rotate Left</b-button>
</span> <span class="control">        <b-button type="is-secondary" @click="rotate(90)">Rotate Right</b-button>
</span></b-field>
    </section>
    <footer v-if="inModal" class="modal-card-foot ">
      <b-field expanded grouped position="is-centered" style="width: 100%">

        <b-button type="is-primary" @click="crop()"
        >Save
        </b-button>
        <b-button outlined type="is-primary" @click="$emit('close')"
        >Cancel
        </b-button>
      </b-field>
    </footer>
    <b-field v-else class="mt-4" grouped>
      <div class="control">
        <b-button type="submit"
                  value="Save" @click="crop()">Save
        </b-button>
      </div>
    </b-field>

  </div>
</template>
<script>
import Vue from 'vue';
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css'

Vue.use(VueCroppie);


export default {
  mounted() {
    this.croppie(this.image)

    // Upon mounting of the component, we accessed the .bind({...})
    // function to put an initial image on the canvas.
    // this.$refs.croppieRef.bind({
    //   url: 'http://i.imgur.com/Fq2DMeH.jpg',
    // })
  },
  data() {
    return {
      cropped: null,

    }
  },
  props: {
    image: {
      required: true
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
  },

  methods: {
    croppie(e) {
      var reader = new FileReader();
      reader.onload = e => {
        this.$refs.croppieRef.bind({
          url: e.target.result
        });
      };
      reader.readAsDataURL(e);
    },

    // CALLBACK USAGE
    crop() {
      // Here we are getting the result via callback function
      // and set the result to this.cropped which is being
      // used to display the result above.
      let options = {
        type: 'blob',
        size: {width: 500, height: 500},
        format: 'jpeg',
        circle: false
      }
      this.$refs.croppieRef.result(options, (output) => {
        this.$emit('cropped', output)
        this.$emit('close')
      });
    },
    // EVENT USAGE
    // cropViaEvent(options) {
    //   this.$refs.croppieRef.result(options);
    // },
    result(output) {
      this.cropped = output;
    },
    // update(val) {
    //   console.log(val);
    // },
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    }
  }
}
</script>
